<div>
<button class="extended-fab-button" mat-mini-fab  (click)="onBackClick()"
	style="transition: 1s ease-in-out; color:primary; background: #b5002b;">
		<mat-icon>keyboard_backspace</mat-icon></button></div>

<h2 mat-dialog-title align="center" i18n >Departure: {{stop}} </h2>
		
<div mat-dialog-content style="max-height:75vh">

	<table cdk-table *ngFor="let data of observableDepartureOverviewResluts | async " style="width:100%; table-layout:fixed">
 			<td style="text-align:center"> {{ data.overview_departure_time }} </td>
 			<td style="text-align:center"> {{data.line_transportProduct}} {{data.line_label}} </td>
  		 
 			<td style="text-align:center"> <mat-chip color="{{ data.occupancy == 'UNKNOWN' ? 'unknown' : (data.occupancy == 'SPARSE' ? 'unknown' : 
		         	( data.occupancy == 'SEATING_HALF_OCCUPIED' ? 'unknown' : 'unknown' )) }}" selected style="padding: 7px 5px; min-height: 20px" >
        
		           <img *ngIf="data.occupancy == 'UNKNOWN'" src="assets/img/unknown.svg" class="image-icon" style="height: 20px;"/>
		           <img *ngIf="data.occupancy == 'SPARSE'" src="assets/img/empty.svg" class="image-icon" style="height: 20px;"/>
		           <img *ngIf="data.occupancy == 'SEATING_HALF_OCCUPIED'" src="assets/img/half.svg" class="image-icon" style="height: 20px;"/>
		           <img *ngIf="data.occupancy == 'SEATING_OCCUPIED'" src="assets/img/full.svg" class="image-icon" style="height: 20px;"/>
		           <img *ngIf="data.occupancy == 'CROWDED'" src="assets/img/crowded.svg" class="image-icon" style="height: 20px;"/>
		           </mat-chip> </td>
    </table>
