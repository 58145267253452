<div>
<button class="fab-button" mat-mini-fab  (click)="onBackClick()"
	style="transition: 1s ease-in-out; color:primary; background: #b5002b;">
		<mat-icon>keyboard_backspace</mat-icon></button></div>
<h2 mat-dialog-title align="center" i18n *ngIf="busLine != undefined; else elseBlock">Line: {{busLine}} </h2>
		
 <div mat-dialog-content style="max-height:75vh" *ngIf="busLine != undefined;">  
 	<div align="center">
	 	<mat-button-toggle-group [value]="2" (change)="toggleView($event)">
	  		<mat-button-toggle [value]="2" style="height: 38px;"><mat-icon style="font-size: 15px;">arrow_back</mat-icon></mat-button-toggle>
	  		<mat-button-toggle [value]="1" style="height: 38px;"><mat-icon style="font-size: 15px;">arrow_forward</mat-icon></mat-button-toggle>
		</mat-button-toggle-group>
    </div>
		<table cdk-table *ngFor="let row of observableOccupationOverviewResluts | async " style="width:100%; table-layout:fixed">
	    	<td *ngIf="row.line_direction == toggle" style="text-align:center"> {{ row.overview_departure_time}} </td>
	    	<td *ngIf="row.line_direction == toggle" style="text-align:center"> {{ row.stop_label}} </td>
	    	<td *ngIf="row.line_direction == toggle" style="text-align:center"> <mat-chip color="{{ row.occupancy == 'UNKNOWN' ? 'unknown' : (row.occupancy == 'SPARSE' ? 'unknown' : 
			         	( row.occupancy == 'SEATING_HALF_OCCUPIED' ? 'unknown' : 'unknown' )) }}" selected style="padding: 7px 5px; min-height: 20px">
			           <img *ngIf="row.occupancy == 'UNKNOWN'" src="assets/img/unknown.svg" class="image-icon" style="height: 20px;"/>
			           <img *ngIf="row.occupancy == 'SPARSE'" src="assets/img/empty.svg" class="image-icon" style="height: 20px;"/>
			           <img *ngIf="row.occupancy == 'SEATING_HALF_OCCUPIED'" src="assets/img/half.svg" class="image-icon" style="height: 20px;"/>
			           <img *ngIf="row.occupancy == 'SEATING_OCCUPIED'" src="assets/img/full.svg" class="image-icon" style="height: 20px;"/>
			           <img *ngIf="row.occupancy == 'CROWDED'" src="assets/img/crowded.svg" class="image-icon" style="height: 20px;"/>
			           </mat-chip> </td>
		</table>
 </div>  
<ng-template #elseBlock>  
 	<h3 align="center" i18n>Please help us by filling Occupancy!</h3>
 	<div align="center">
 	<button routerLink="/occupancy" queryParamsHandling="merge" (click)="onBackClick()"
		class="extended-fab-button" mat-fab color="primary"
		style="transition: 1s ease-in-out; color:primary; background: #b5002b;">
			<mat-icon>add</mat-icon>
				<span class="extended-fab-button__text" i18n>Report occupation</span>
	</button>
  </div>
</ng-template>  
 