<div class="departure">

   <mat-table class="mat-elevation-z8 small-table" matSort>

	    <ng-container matColumnDef="line">
	      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Line</mat-header-cell>
	      <mat-cell *matCellDef="let row">{{row.line.transportProduct}} {{row.line.label}}</mat-cell>
	    </ng-container>  
	
	    <ng-container matColumnDef="destination">
	      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Destination</mat-header-cell>
	      <mat-cell *matCellDef="let row">{{row.destination}}</mat-cell>
	    </ng-container>

        <ng-container matColumnDef="departure">
	      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Departure</mat-header-cell>
	      <mat-cell *matCellDef="let row">{{(row.plannedTime != null ? row.plannedTime : row.predictedTime) | date:'HH:mm':'GMT+1':'de-DE'}}</mat-cell>
	      <!-- replace HH:mm with 'shortTime' when we set the locale to German -->
	    </ng-container>
	
	    <ng-container matColumnDef="occupation">
	      <mat-header-cell *matHeaderCellDef i18n>Occupation</mat-header-cell>
	      
		      <mat-cell *matCellDef="let row">
		       	<mat-chip-list selectable multiple>
		         <mat-chip color="{{ row.predictedOccupation == 'UNKNOWN' ? 'unknown' : (row.predictedOccupation == 'SPARSE' ? 'unknown' : 
		         	( row.predictedOccupation == 'SEATING_HALF_OCCUPIED' ? 'unknown' : 'unknown' )) }}" selected (click)='occupationOverview(row)' >
		           <img *ngIf="row.predictedOccupation == 'UNKNOWN'" src="assets/img/unknown.svg" class="image-icon" style="height: 30px;"/>
		           <img *ngIf="row.predictedOccupation == 'SPARSE'" src="assets/img/empty.svg" class="image-icon" style="height: 30px;"/>
		           <img *ngIf="row.predictedOccupation == 'SEATING_HALF_OCCUPIED'" src="assets/img/half.svg" class="image-icon" style="height: 30px;"/>
		           <img *ngIf="row.predictedOccupation == 'SEATING_OCCUPIED'" src="assets/img/full.svg" class="image-icon" style="height: 30px;"/>
		           <img *ngIf="row.predictedOccupation == 'CROWDED'" src="assets/img/crowded.svg" class="image-icon" style="height: 30px;"/>
		         </mat-chip>
		        </mat-chip-list>
		      </mat-cell>
	     
	      </ng-container>

	 <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

     <mat-row *matRowDef="let row; columns: displayedColumns" ></mat-row>
    
   </mat-table>

   <mat-paginator #paginator 
   [length]="dataSource?.departureLength"
              [pageSize]="5"
              [pageIndex]="0"
              [pageSizeOptions]="[5,10]"
             ></mat-paginator>
                  
 </div>
