<div *ngFor="let post of blogData">
	<div align="center">
		<mat-chip >
			<a href="{{post.link}}" target="_blank" class="background-title">{{post.title.rendered}}  <img src="assets/img/open_in_new_black.svg" style="height: 15px;" /> </a>
		</mat-chip>
		<p></p>
		<p [innerHTML]='post.excerpt.rendered'></p>
	</div>
	<div layout-xs="column" class="img-blog">
		<a href="{{post.link}}" target="_blank"><img src="{{ post.featured_image_src }}" class="img-blog-size"></a>
	</div>
	<p></p>
	<mat-divider></mat-divider>
	<p></p>
</div>
