import { Component, Inject } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { OccupationOverviewResult, StopDeparturesDTO } from '../apiDataTypes';
import { ApiService } from '../api.service';
import { Observable, of } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
@Component({
	selector: 'app-departurereport',
	templateUrl: './departurereport.component.html',
	styleUrls: ['./departurereport.component.css']
})
export class DeparturereportComponent {

	stopDepartures: Observable<StopDeparturesDTO[]>;

	/** Based on the screen size, switch from standard to one column per row */
	cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
		map(({ matches }) => {
			if (matches) {
				return [
					{ title: 'Card 1', cols: 1, rows: 1 }
				];
			}

			return [
				{ title: 'Card 1', cols: 2, rows: 1 }
			];
		})
	);

	constructor(private apiService: ApiService, private breakpointObserver: BreakpointObserver,
		public dialog: MatDialog) {
		this.stopDepartures = this.apiService.getStopDepartures();
	}



	/** this is to get available lines crossing this stop, based on their departures and how much the line is occupied. */
	originClick(stop: any) {

		this.dialog.open(DepartureReportDialogComponent, {
			closeOnNavigation: true,
			data: stop,
			width: '100vh',
			height: '100vh',
			maxWidth: '100vh',
			maxHeight: '100vh',

		}).backdropClick().subscribe(e => history.back());

	}

}

@Component({
	selector: 'departurereport-dialog',
	templateUrl: './departurereport-dialog.html',
	styleUrls: ['./departurereport-dialog.component.css']
})
export class DepartureReportDialogComponent {

	stop: string;
	private stopOverviewMap: Map<string, OccupationOverviewResult[]> = new Map<string, OccupationOverviewResult[]>();
	observableDepartureOverviewResluts: Observable<OccupationOverviewResult[]>;
	private sort: MatSort;

	constructor(private apiService: ApiService,
		private dialogRef: MatDialogRef<DepartureReportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		console.log("==>>>>>>>>>>>>>>>>>" + data);

		if (!this.stopOverviewMap.has(data)) {
			this.stopOverviewMap.set(data, []);
			this.apiService.getStopOverview(data).subscribe(callresult => {
				callresult.sort((a, b) => a.overview_departure_time.localeCompare(b.overview_departure_time))
				for (var i of callresult) {
					this.stopOverviewMap.get(data).push(i);
					this.stop = callresult[0].stop_label;
				}
			});

		}

		this.observableDepartureOverviewResluts = of(this.stopOverviewMap.get(data));

	}

	onBackClick(): void {
		this.dialogRef.close();
	}

}

