import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { BackgroundDetailsComponent } from './background-details/background-details.component';
import { DeparturereportComponent } from './departurereport/departurereport.component';
import { DriverOccupancyComponent } from './driver-occupancy/driver-occupancy.component';
import { ReportOccupancyComponent } from './report-occupancy/report-occupancy.component';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';

const routes: Routes = [
{ path: 'departures', component: DeparturereportComponent },
{ path: 'occupancy', component: ReportOccupancyComponent },
{ path: 'driver', component: DriverOccupancyComponent },
{ path: 'partners', component: PartnerDetailsComponent },
{ path: 'background', component: BackgroundDetailsComponent },
{ path: '', component: DeparturereportComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),ChartsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
