import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
	selector: 'app-backgrounddetails',
	templateUrl: './background-details.component.html',
	styleUrls: ['./background-details.component.css']
})

export class BackgroundDetailsComponent implements OnInit {

	blogData;
	loading = false;
	constructor(public apiService: ApiService) { }
	ngOnInit(): void {
		this.getBackgroundBlog();
	}
	getBackgroundBlog() {
		this.loading = true;
		this.apiService.getBackgroundBlog().subscribe((response) => {
			console.log(response);
			this.loading = false;
			this.blogData = response;
		});
	}
}