<form [formGroup]="occupancyForm" novalidate (ngSubmit)="onSubmit()">
	<mat-card-content>
  	<div class="row">
		<mat-form-field class="full-width" floatLabel="never">
		<mat-label i18n>Departure station
			<ng-template #likelyDeparatureStation>Departure station</ng-template>
		</mat-label> <mat-hint i18n>Enter departure station</mat-hint> 
		<input i18n-placeholder
			   placeholder="Departure station"
			   type="text" 
			   formControlName="origin" 
			   matInput
			   [formControl]="myControl" 
			   [matAutocomplete]="auto"
			   required> 
		<mat-autocomplete #auto="matAutocomplete">
		<mat-option *ngIf="isLoading" class="is-loading"> 
		<mat-spinner diameter="50"></mat-spinner>
		</mat-option> 
		<ng-container *ngIf="!isLoading" >
		<mat-option *ngFor="let departureStations of suggestedDepartureOrigins | async" [value]="departureStations">
		<span>{{departureStations}}</span>
		</mat-option> 
		</ng-container> 
		</mat-autocomplete> 
		</mat-form-field>
	</div>
		
 	<div class="row">
		<mat-form-field class="full-width" floatLabel="never">
		<mat-label i18n>Destination station
			<ng-template #likelyDestinationStation>Destination station</ng-template>
		</mat-label> <mat-hint i18n>Enter destination station</mat-hint> 
		<input i18n-placeholder
			   placeholder="Destination station"
			   type="text" 
			   formControlName="destination" 
			   matInput
			   [formControl]="myControl" 
			   [matAutocomplete]="autoNationality" required> 
		<mat-autocomplete #autoNationality="matAutocomplete">
		<mat-option *ngIf="isLoading" class="is-loading"> 
		<mat-spinner diameter="50"></mat-spinner>
		</mat-option> 
		<ng-container *ngIf="!isLoading" >
		<mat-option *ngFor="let destinationStations of suggestedDestinationOrigins | async" [value]="destinationStations">
		<span>{{destinationStations}}</span>
		</mat-option> 
		</ng-container> 
		</mat-autocomplete> 
		</mat-form-field>
	</div>
	
	<div class="row">
		<mat-form-field class="full-width" floatLabel="never">
		<mat-label i18n>Station line
			<ng-template #likelyLines>Station line</ng-template>
		</mat-label> <mat-hint i18n>Enter station line</mat-hint> 
		<input i18n-placeholder
			   placeholder="Station line"
			   type="text" 
			   formControlName="line" 
			   matInput
			   [formControl]="myControl" 
			   [matAutocomplete]="autoNationality2" required> 
		<mat-autocomplete #autoNationality2="matAutocomplete">
		<mat-option *ngIf="isLoading" class="is-loading"> 
		<mat-spinner diameter="50"></mat-spinner>
		</mat-option> 
		<ng-container *ngIf="!isLoading" >
		<mat-option *ngFor="let lines of suggestedLines | async" [value]="lines">
		<span>{{lines}}</span>
		</mat-option> 
		</ng-container> 
		</mat-autocomplete> 
		</mat-form-field>
	</div>
	<hr />

	
	<mat-error *ngIf="occupancyForm.get('perceivedOccupation').hasError('required')">{{errorPerceivedOccupation}}</mat-error>  
	<div class="row">
	<mat-button-toggle-group formControlName="perceivedOccupation"> 
		<mat-button-toggle value="SPARSE">
			<img src="assets/img/empty.svg" class="image-icon" style="height: 30px;"/> 
		</mat-button-toggle> 
		<mat-button-toggle value="SEATING_HALF_OCCUPIED">
			<img src="assets/img/half.svg" class="image-icon" style="height: 30px;"/>
		</mat-button-toggle> 
		<mat-button-toggle value="SEATING_OCCUPIED"> 
			<img src="assets/img/full.svg" class="image-icon" style="height: 30px;"/>
		</mat-button-toggle> 
		<mat-button-toggle value="CROWDED">
			<img src="assets/img/crowded.svg" class="image-icon" style="height: 30px;"/>
		</mat-button-toggle> 
	</mat-button-toggle-group>
	</div>
	<hr/>
	
	<mat-error *ngIf="occupancyForm.get('faceMask').hasError('required')">{{errorFaceMask}}</mat-error>   
	<div class="row">
	<mat-button-toggle-group formControlName="faceMask"> 
		<mat-button-toggle value="1">
			<img src="assets/img/mask.svg" class="image-icon" style="height: 30px;"/> 
		</mat-button-toggle> 
		<mat-button-toggle value="2">
			<img src="assets/img/nomask.svg" class="image-icon" style="height: 30px;"/>
		</mat-button-toggle> 
	</mat-button-toggle-group>
	</div>
	<hr/>

	</mat-card-content>
		
	<mat-card-actions>
		<div>
	      	<button mat-fab style="background: #b5002b;">
				<img src="assets/img/upload.svg" class="image-icon" style="height: 30px;"/>
	      	</button>
      	</div>
	</mat-card-actions>
</form>
