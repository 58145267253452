import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { Departure } from '../apiDataTypes';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
/**
 * Data source for the DepartureTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class DepartureTableDataSource extends DataSource<Departure>{

	private departureSubject = new BehaviorSubject<Departure[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);

	public loading$ = this.loadingSubject.asObservable();

	public departureLength: number = 0;

	private paginator: MatPaginator;
	private sort: MatSort;

	constructor() {
		super();
		this.departureSubject.next([]);
	}

	public ngOnInitCompanion() {

	}

	ngAfterViewInitCompanion(departures, paginator, sort) {
		this.paginator = paginator;
		this.sort = sort;
		setTimeout(() => {
			this.departureSubject.next(departures);
			this.departureLength=departures.length;
		}, 0);

	}

	/**
	 * Connect this data source to the table. The table will only update when
	 * the returned stream emits new items.
	 * @returns A stream of the items to be rendered.
	 */
	connect(): Observable<Departure[]> {

		const dataMutations = [

			this.departureSubject.asObservable(),
			this.paginator.page,
			this.sort.sortChange  
		];
		
		return merge(...dataMutations).pipe(map(() => {
			return this.getSortedData(this.getPagedData([...this.departureSubject.getValue()]));
		}));
	}

	/**
	 * Paginate the data (client-side). If you're using server-side pagination,
	 * this would be replaced by requesting the appropriate data from the server.
	 */
	private getPagedData(data: Departure[]) {
		const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
		return data.splice(startIndex, this.paginator.pageSize);
	}

	/**
	 * Sort the data (client-side). If you're using server-side sorting,
	 * this would be replaced by requesting the appropriate data from the server.
	 */
	private getSortedData(data: Departure[]) {
		if (!this.sort.active || this.sort.direction === '') {
			return data;
		}

		return data.sort((a, b) => {
			var aLine = a.line.transportProduct + a.line.label;
			var bLine = b.line.transportProduct + b.line.label;
			
			const isAsc = this.sort.direction === 'asc';
			switch (this.sort.active) {
				case 'departure': return compare(new Date(a.predictedTime).getTime(), new Date(b.predictedTime).getTime(), isAsc);
				case 'destination': return compare(new String(a.destination).toString(), new String(b.destination).toString(), isAsc);
				case 'line': return compare(new String(aLine).toString(), new String(bLine).toString(), isAsc);
				default: return 0;
			}
		});
	}

	/**
	 *  Called when the table is being destroyed. Use this function, to clean up
	 * any open connections or free any held resources that were set up during connect.
	 */
	disconnect() {
		this.departureSubject.complete();
		this.loadingSubject.complete();
	}

}


/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number | Date , b: string | number | Date, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


