// added localize constants here to identify line number for transaltion files 
const errorPerceivedOccupation = $localize`:@@percievedOccupation.errorMessage:Perceived Occupation *`;
const errorFaceMask = $localize`:@@faceMask.errorMessage:Face Mask *`;

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { Suggestion, RideOccupationReportDTO } from '../apiDataTypes';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

//https://stackblitz.com/edit/angular-material-autocomplete-async2?file=src%2Fapp%2Fapp.component.ts
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportOccupancyDialogComponent } from './report-occupancy-dialog.component';


@Component({
	selector: 'app-report-occupancy',
	templateUrl: './report-occupancy.component.html',
	styleUrls: ['./report-occupancy.component.css']
})
export class ReportOccupancyComponent implements OnInit {

	occupancyForm = this.fb.group({
		origin: null,
		destination: [null, Validators.required],
		line: [null, Validators.required],
		perceivedOccupation: [null, Validators.required],
		faceMask: [null, Validators.required]
	});

	myControl = new FormControl();

	observableSuggestions: Observable<Suggestion>;

	suggestedDepartureOrigins: Observable<string[]>;
	likelyDeparatureStation: Observable<string>;

	suggestedDestinationOrigins: Observable<string[]>;
	likelyDestinationStation: Observable<string>;

	suggestedLines: Observable<string[]>;
	likelyLines: Observable<string>;

	isLoading = false; // loaded for the backend when the spinner is used

	lastlocation: any;

	objReport: RideOccupationReportDTO;

	constructor(
		private fb: FormBuilder,
		private apiService: ApiService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private snackBar: MatSnackBar,
		public dialog: MatDialog) {

		this.activatedRoute.queryParams.subscribe(data => {
			console.log(data);
		});

		this.observableSuggestions = this.apiService.getSuggestions();
		this.observableSuggestions.subscribe(suggestedOrigins => {

			// This will retrieve the first element from the suggestions depature stations array and associate it with the likely departure stations variable
			this.likelyDeparatureStation = this.observableSuggestions.pipe(map(arr => arr.departureStations[0])); 
			this.likelyDestinationStation = this.observableSuggestions.pipe(map(arr => arr.destinationStations[0]));
			this.likelyLines = this.observableSuggestions.pipe(map(arr => arr.lines[0]));

			// listening to user entry auto suggestion from api and departures nearer to latest geo location captured.
			this.listenToUserEntrySuggestionsFilter();
		});

	}

	//accesses the UI field and once a value changes by user entry of letters it pipes the already entered string into a filter 
	//to show only the matching suggestions
	private listenToUserEntrySuggestionsFilter() {

		this.suggestedDepartureOrigins = this.occupancyForm
			.get('origin')
			.valueChanges.pipe(startWith(''),
				withLatestFrom(this.observableSuggestions),
				map(([q, observableSuggestions]) => {
					const filterValue = q.toLowerCase();

					return observableSuggestions.departureStations.filter(suggestion => suggestion.toLowerCase()
						.indexOf(filterValue) > -1);
				})
			);

		this.suggestedDestinationOrigins = this.occupancyForm
			.get('destination')
			.valueChanges.pipe(startWith(''),
				withLatestFrom(this.observableSuggestions),
				map(([q, observableSuggestions]) => {
					const filterValue = q.toLowerCase();

					return observableSuggestions.destinationStations.filter(suggestion => suggestion.toLowerCase()
						.indexOf(filterValue) > -1);
				})
			);

		this.suggestedLines = this.occupancyForm
			.get('line')
			.valueChanges.pipe(startWith(''),
				withLatestFrom(this.observableSuggestions),
				map(([q, observableSuggestions]) => {
					const filterValue = q.toLowerCase();

					return observableSuggestions.lines.filter(suggestion => suggestion.toLowerCase()
						.indexOf(filterValue) > -1);
				})
			);

	}

	ngOnInit() {
	}

	errorPerceivedOccupation: any; // display mandatory error message for percieved occupation input
	errorFaceMask: any; // display mandatory error message for face mask input
	// To post the ride report information to backend
	onSubmit() {
		if (this.occupancyForm.value.perceivedOccupation == null)
			this.errorPerceivedOccupation = errorPerceivedOccupation;

		if (this.occupancyForm.value.faceMask == null)
			this.errorFaceMask = errorFaceMask;

		if (this.occupancyForm.value.origin != null && this.occupancyForm.value.destination != null &&
			this.occupancyForm.value.line != null && this.occupancyForm.value.perceivedOccupation != null &&
			this.occupancyForm.value.faceMask != null) {
			console.log(this.occupancyForm.value);
			console.log(this.apiService.lastlocation);
			var rideInput = {
				"departure" : new Date(), // this has to come from api or really need to capture?
				"line" : this.occupancyForm.value.line,
				"perceivedOccupation" : this.occupancyForm.value.perceivedOccupation,
				"faceMask" : this.occupancyForm.value.faceMask,
				"origin" : this.occupancyForm.value.origin,
				"destination" : this.occupancyForm.value.destination,
				// last geo locations 
				"lastPositions" : [{
						"timestamp" : new Date(),
						"position" : [this.apiService.lastlocation.lon, this.apiService.lastlocation.lat] 
					}] 
			};
			this.apiService.postOccupationReport(rideInput)
				.subscribe(
					response => {
						this.openRideReportDialog();
						console.log('Success', response.status)  // this is to log the response status
					});
		}

	}

	// this is a popup snack bar on success/error of a post api call -- future refernce
	responseSnackBar(message: string) {
		this.snackBar.open(message, "", { duration: 2000, });
	}

	// this is to open a dialog on success.error of a post api call
	openRideReportDialog() {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;

		this.dialog.open(ReportOccupancyDialogComponent, dialogConfig);
	}

}


