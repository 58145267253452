import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: 'report-occupancy-dialog',
    templateUrl: './report-occupancy-dialog.html'
})
export class ReportOccupancyDialogComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<ReportOccupancyDialogComponent>) {
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }
}
