<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar i18n>Menu</mat-toolbar>
    
    <mat-nav-list>
    	<mat-list-item [routerLink]="['/departures']" queryParamsHandling="merge">
        	<mat-icon>item</mat-icon>
        	<a matLine i18n>Departures</a>
    	</mat-list-item>
		
		<mat-list-item [routerLink]="['/occupancy']" queryParamsHandling="merge">
        	<mat-icon>item</mat-icon>
        	<a matLine i18n>Occupancy</a>
    	</mat-list-item>
    	
    	<mat-list-item [routerLink]="['/driver']" queryParamsHandling="merge" *ngIf="isDriver">
        	<mat-icon>item</mat-icon>
        	<a matLine i18n>Driver</a>
    	</mat-list-item>
    	
    	<mat-list-item [routerLink]="['/partners']" queryParamsHandling="merge">
        	<mat-icon>item</mat-icon>
        	<a matLine i18n>Partners</a>
    	</mat-list-item>
    	
    	<mat-list-item [routerLink]="['/background']" queryParamsHandling="merge">
        	<mat-icon>item</mat-icon>
        	<a matLine i18n>Background</a>
    	</mat-list-item>
    
	</mat-nav-list>
    
    
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
    	<h1 class="mat-h1"> {{ title }} </h1>  
    </mat-toolbar>
   <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
