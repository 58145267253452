import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { DeparturereportComponent, DepartureReportDialogComponent } from './departurereport/departurereport.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DepartureTableComponent, DepartureTableOccupationDialogComponent } from './departure-table/departure-table.component';
import { ReportOccupancyComponent } from './report-occupancy/report-occupancy.component';
import { ReportOccupancyDialogComponent } from './report-occupancy/report-occupancy-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { MatTabsModule } from '@angular/material/tabs';

import { MyPaginatorIntl } from './app-paginator-intl';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AppPermissionDialog } from './api.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { DriverOccupancyComponent } from './driver-occupancy/driver-occupancy.component';
import { DriverCodeDialogComponent } from './nav/nav-driver-code-dialog.component';
import { BackgroundDetailsComponent } from './background-details/background-details.component';
import { CookieService } from 'ngx-cookie-service';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';
import { RouterModule } from '@angular/router';

registerLocaleData(localeDe);

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		DeparturereportComponent,
		DepartureTableComponent,
		ReportOccupancyComponent,
		ReportOccupancyDialogComponent,
		AppPermissionDialog,
		DriverOccupancyComponent,
		DriverCodeDialogComponent,
		DepartureTableOccupationDialogComponent,
		DepartureReportDialogComponent,
		BackgroundDetailsComponent,
		PartnerDetailsComponent
	],
	imports: [
		MatProgressSpinnerModule,
		FormsModule,
		BrowserModule,
		HttpClientModule,
		MatSliderModule,
		MatAutocompleteModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		LayoutModule,
		MatToolbarModule,
		MatButtonModule,
		MatSidenavModule,
		MatIconModule,
		MatListModule,
		MatGridListModule,
		MatCardModule,
		MatMenuModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatChipsModule,
		MatInputModule,
		MatSelectModule,
		MatRadioModule,
		MatButtonToggleModule,
		ReactiveFormsModule,
		CommonModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
    	ReactiveFormsModule,
    	MatTooltipModule,
		MatSnackBarModule,
		MatDialogModule,
		MatTabsModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.pwaServiceWorker })
	],
	providers: [{provide: CookieService},
		{ provide: MatPaginatorIntl, 
		  useValue: MyPaginatorIntl() },
		{provide: MatDialogRef , useValue:{}},
		
	],
		
	bootstrap: [AppComponent],
	entryComponents: [ReportOccupancyDialogComponent, AppPermissionDialog, 
					  DriverCodeDialogComponent, DepartureTableOccupationDialogComponent, 
					  DepartureReportDialogComponent]
})
export class AppModule { }
