import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'nav-driver-code-dialog',
	templateUrl: './nav-driver-code-dialog.html'
})
export class DriverCodeDialogComponent implements OnInit {
	drivercode: any;
	constructor(private cookieService: CookieService,
		private dialogDriverRef: MatDialogRef<DriverCodeDialogComponent>,
		private route: ActivatedRoute, private router: Router) {

	}

	ngOnInit() {
		if (this.cookieService.check('drivercode')) {
			this.drivercode = this.cookieService.get('drivercode')
		} else 
			this.drivercode = '';
	}

	confirm(code) {
		this.cookieService.set('drivercode', code, 365);
		this.dialogDriverRef.close();
	}
}
