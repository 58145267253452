import { AfterViewInit, Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Departure, OccupationOverviewResult } from '../apiDataTypes';
import { DepartureTableDataSource } from './departure-table-datasource';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
	selector: 'app-departure-table',
	templateUrl: './departure-table.component.html',
	styleUrls: ['./departure-table.component.css']
})
export class DepartureTableComponent implements AfterViewInit, OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatTable) table: MatTable<Departure>;

	// Injecting departures - https://angular-2-training-book.rangle.io/components/app_structure_with_components/passing_data_into_components
	@Input() departures: Observable<Departure[]>;
	dataSource: DepartureTableDataSource;

	/** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
	displayedColumns = ['line', 'destination', 'departure', 'occupation'];

	constructor(private route: ActivatedRoute, public dialog: MatDialog) {
		this.dataSource = new DepartureTableDataSource();
	}

	ngOnInit() {
		this.dataSource.ngOnInitCompanion();
	}

	ngAfterViewInit() {
		this.dataSource.ngAfterViewInitCompanion(this.departures, this.paginator, this.sort);
		this.table.dataSource = this.dataSource;
	}

	// this is to get next rides from this line and departures and how much they are occupied.	
	occupationOverview(event: any) {
		var line = event.line.geoKey;

		this.dialog.open(DepartureTableOccupationDialogComponent, {
			closeOnNavigation: true,
			data: line,
			width: '100vh',
			height: '100vh',
			maxWidth: '100vh',
			maxHeight: '100vh'
		}).backdropClick().subscribe(e => history.back());
	}

}

@Component({
	selector: 'departure-table-occupation-dialog',
	templateUrl: './departure-table-occupation-dialog.html',
	styleUrls: ['./departure-table-occupation-dialog.css']
})
export class DepartureTableOccupationDialogComponent {

	busLine: string;
	toggle: string = '2';
	private lineOverviewMap: Map<string, OccupationOverviewResult[]> = new Map<string, OccupationOverviewResult[]>();
	observableOccupationOverviewResluts: Observable<OccupationOverviewResult[]>;

	constructor(private apiService: ApiService,
		private dialogRef: MatDialogRef<DepartureTableOccupationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
			console.log("++>>>>>>>>>>>>>>>>>" + data);

		if (!this.lineOverviewMap.has(data)) {
			this.lineOverviewMap.set(data, []);
			this.apiService.getLineOverview(data).subscribe(callresult => {
				callresult.sort((a: any, b: any) => {
					if (!a.line_direction.localeCompare(b.line_direction)) {
						return a.overview_departure_time.localeCompare(b.overview_departure_time);
					}
					return a.line_direction.localeCompare(b.line_direction);
				})

				for (var i of callresult) {
					this.lineOverviewMap.get(data).push(i);
					this.busLine = callresult[0].line_transportProduct+" "+ callresult[0].line_name;
				}
			});

		}
		this.observableOccupationOverviewResluts = of(this.lineOverviewMap.get(data));

	}

	onBackClick(): void {
		this.dialogRef.close();
	}

	toggleView(change: MatButtonToggleChange) {
		this.toggle = change.value;
	}

}

