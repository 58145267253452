import { Component, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {
	Suggestion, RideOccupationReportDTO, StopDeparturesDTO, RideReportStatus,
	DriverRideOccupationReportDTO, DriverSuggestion, OccupationOverviewResult
} from './apiDataTypes';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CookieService } from 'ngx-cookie-service';
import { catchError, retry } from 'rxjs/operators';

// The environment loaded form the environments folder to have different settings for development and production
const API_URL = environment.apiUrl;
// the minimum movement meters that are required to add it to the last locations 
const minMovementMeters = 5;
// the maximum points of saved location history
const locationHistoryPoints = 10;
// allowing access to all cross origin domains
const headers = new HttpHeaders().append('Access-Control-Allow-Origin', '*');

// https://stackblitz.com/angular/pyrgjeodnnl?file=src%2Fapp%2Fhero.service.ts
@Injectable({
	// we declare that this service should be created
	// by the root application injector.
	providedIn: 'root',
})
export class ApiService {

	public stopDepartures = new BehaviorSubject<StopDeparturesDTO[]>([]);

	private stationAndLineSuggestionsCache = <Suggestion>{};

	private stationAndLineSuggestions = new BehaviorSubject<Suggestion>(null);

	private driverSuggestionsCache = <DriverSuggestion>{};

	private driverSuggestions = new BehaviorSubject<DriverSuggestion>(null);

	lastlocation: any;

	lastLocationArry: any = [];

	// These class variables we are using for Occupancy not to repeate the same code to get geoLocations
	public lat: any;
	public lon: any;

	// The coordinate that is set in the URL via a query parameter.
	// e.g. fahrbar.app?coordinates=8.9;49.1 or fahrbar.app?coordinates=8.9,49.1 
	// a coordinate pair is fined as longitude;latitude - a "," or ";" can be used as seperator
	private urlCoordinate: string;
	departures: any;

	isDriver: boolean = false;
	background_base_path = 'https://iunera.com/wp-json/wp/v2/posts?categories=120784';
	
	constructor(public dialog: MatDialog,
		private http: HttpClient,
		private route: ActivatedRoute,
		private cookieService: CookieService
	) {

		this.stationAndLineSuggestionsCache.departureStations = [];
		this.stationAndLineSuggestionsCache.destinationStations = [];
		this.stationAndLineSuggestionsCache.lines = [];

		this.driverSuggestionsCache.departureStations = [];
		this.driverSuggestionsCache.destinationStations = [];
		this.driverSuggestionsCache.lines = [];

		this.route.queryParams
			.subscribe(params => {
				console.log(params);
				this.urlCoordinate = params.coordinates;
				console.log(this.urlCoordinate + "....");
			});

		if (this.cookieService.get('drivercode') != undefined) // enable for driver 
			this.isDriver = true;

		if (navigator.geolocation) {
			this.getLocation().subscribe(position => {

				var lat = position.coords.latitude;
				var lon = position.coords.longitude;
				console.log(lat);
				console.log(lon);

				this.lastlocation = { "lon": lon, "lat": lat };
				var movementHappened = this.collectLastGeolocations(this.lastlocation);

				if (movementHappened) {

					this.loadNearDepartures(lon, lat);
					this.updateOfSuggestions(lon, lat);

					if (this.isDriver)
						this.updateDriverSuggestions(lon, lat);
				}
			})
		};
		// Installing a listener for new departure objects. Once depatures are detected, we add them reactively to the suggestions
		this.stopDepartures.subscribe(update =>
			this.fillAutoSuggestionsFromDepartureData());

		// Installing a listener for new departure objects. Once depatures are detected, we add them reactively to the driver suggestions	
		if (this.isDriver) {
			this.stopDepartures.subscribe(dUpdate =>
				this.fillDriverAutoSuggestionsFromDepartureData());

		}
	}

	/**Loading near departures. When no valus is given default wise a geoocordinate of Mannheim is gets used.*/
	loadNearDepartures(lon: '8.4631697', lat: '49.4858018') {
		var coordinates = lon + ";" + lat;

		if (this.urlCoordinate != null) {
			coordinates = this.urlCoordinate;
		}
		var stopDeparturesDTO = this.http.get<StopDeparturesDTO[]>(API_URL + '/departures', { params: new HttpParams().set("coordinates", coordinates) });
		stopDeparturesDTO.subscribe(stopDeparturesArry => {

			this.stopDepartures.next(stopDeparturesArry);

		});

	}

	/**Fetching Occupation Overview for next rides with same line, next stop and current departure time*/
	getLineOverview(lineId: string): Observable<OccupationOverviewResult[]> {
		return this.http.get<OccupationOverviewResult[]>(API_URL + '/occupation/line/overview',
			{ params: new HttpParams().set("time", new Date().toISOString()).set("lineId", lineId) });
	}

	/**Fetching Departure Overview for a day with stopId */
	getStopOverview(stopId: string): Observable<OccupationOverviewResult[]> {
		return this.http.get<OccupationOverviewResult[]>(API_URL + '/departure/overview',
			{ params: new HttpParams().set("stopId", stopId) });

	}

	// this function returns all stop departures...
	getStopDepartures(): Observable<StopDeparturesDTO[]> {
		return this.stopDepartures;
	}

	// This is post call to provide ride report information
	public postOccupationReport(rideOccupationReportDTO: RideOccupationReportDTO): Observable<RideReportStatus> {
		return this.http.post<RideReportStatus>(API_URL + '/ridereports', rideOccupationReportDTO, { headers });
	}

	// This is post call to provide driver ride information
	public postDriverOccupationReport(driverRideOccupationReportDTO: DriverRideOccupationReportDTO): Observable<DriverRideOccupationReportDTO> {
		return this.http.post<DriverRideOccupationReportDTO>(API_URL + '/driver/ridereports', driverRideOccupationReportDTO, { headers });
	}

	/**
	Method to provide an observable location service that updates the location regularly
	*/
	private getLocation(): Observable<any> {
		return Observable.create(observer => {
			if (window.navigator && window.navigator.geolocation) {
				window.navigator.geolocation.getCurrentPosition(
					(position) => {
						observer.next(position);
						observer.complete();
					},
					(error) => {
						this.openLocationPermissionDialog();
						observer.error(error)
					}
				);
			} else {
				observer.error('Unsupported Browser');
			}
		});
	}

	// this is to load the suggestions from api
	updateOfSuggestions(lon: '8.4631697', lat: '49.4858018') {
		var lon = lon;
		var lat = lat;

		// override the lan and lat values with geo location
		if (this.lastlocation != null) {
			lon = this.lon;
			lat = this.lat;
		}

		var suggestionsDTO = this.http.get<Suggestion>(API_URL + '/ridereport/suggestions',
			{ params: new HttpParams().set("lastlocation", JSON.stringify(this.lastLocationArry)) });

		suggestionsDTO.subscribe(suggestioncallresult => {
			//concatinating with departures auto suggestions with api suggestions
			this.stationAndLineSuggestionsCache.departureStations = this.stationAndLineSuggestionsCache.departureStations.concat(suggestioncallresult.departureStations);
			this.stationAndLineSuggestionsCache.destinationStations = this.stationAndLineSuggestionsCache.destinationStations.concat(suggestioncallresult.destinationStations);
			this.stationAndLineSuggestionsCache.lines = this.stationAndLineSuggestionsCache.lines.concat(suggestioncallresult.lines);

			this.stationAndLineSuggestions.next(this.stationAndLineSuggestionsCache);
		});
	}

	// this method to fetch the departures, destinations and line to autofill from departures stations
	private fillAutoSuggestionsFromDepartureData() {
		this.stopDepartures.getValue().forEach(phaseObj => {
			phaseObj.departureDTOs.map((obj) => {
				if (this.stationAndLineSuggestionsCache.destinationStations.indexOf(obj.destination) == -1) {
					this.stationAndLineSuggestionsCache.destinationStations.push(obj.destination);
				}

				if (this.stationAndLineSuggestionsCache.lines.indexOf(obj.line.transportProduct + obj.line.label) == -1) {
					this.stationAndLineSuggestionsCache.lines.push(obj.line.transportProduct + obj.line.label);
				}
			});

			if (this.stationAndLineSuggestionsCache.departureStations.indexOf(phaseObj.originStop.label) == -1) {
				this.stationAndLineSuggestionsCache.departureStations.push(phaseObj.originStop.label);
			}
		});
		this.stationAndLineSuggestions.next(this.stationAndLineSuggestionsCache);
	}

	// this is to load the driver suggestions from api
	updateDriverSuggestions(lon: '8.4631697', lat: '49.4858018') {
		var lon = lon;
		var lat = lat;

		// override the lan and lat values with geo location
		if (this.lastlocation != null) {
			lon = this.lon;
			lat = this.lat;
		}

		var driverSuggestionsDTO = this.http.get<DriverSuggestion>(API_URL + '/driver/suggestions',
			{ params: new HttpParams().set("lastlocation", JSON.stringify(this.lastLocationArry)) });

		driverSuggestionsDTO.subscribe(driverSuggestionResult => {
			//concatinating with departures auto suggestions with driver api suggestions
			this.driverSuggestionsCache.departureStations = this.driverSuggestionsCache.departureStations.concat(driverSuggestionResult.departureStations);
			this.driverSuggestionsCache.destinationStations = this.driverSuggestionsCache.destinationStations.concat(driverSuggestionResult.destinationStations);
			this.driverSuggestionsCache.lines = this.driverSuggestionsCache.lines.concat(driverSuggestionResult.lines);

			this.driverSuggestions.next(this.driverSuggestionsCache);
		});
	}

	// this method to fetch the departures, destinations and line to autofill from departures stations for driver
	private fillDriverAutoSuggestionsFromDepartureData() {
		this.stopDepartures.getValue().forEach(phaseObj => {
			phaseObj.departureDTOs.map((obj) => {
				if (this.driverSuggestionsCache.destinationStations.indexOf(obj.destination) == -1) {
					this.driverSuggestionsCache.destinationStations.push(obj.destination);
				}

				if (this.driverSuggestionsCache.lines.indexOf(obj.line.transportProduct + obj.line.label) == -1) {
					this.driverSuggestionsCache.lines.push(obj.line.transportProduct + obj.line.label);
				}
			});

			if (this.driverSuggestionsCache.departureStations.indexOf(phaseObj.originStop.label) == -1) {
				this.driverSuggestionsCache.departureStations.push(phaseObj.originStop.label);
			}
		});
		this.driverSuggestions.next(this.driverSuggestionsCache);
	}

	// this function returns all suggestions... 
	public getSuggestions(): Observable<Suggestion> {
		return this.stationAndLineSuggestions;
	}

	// this function returns all driver suggestions... 
	public getDriverSuggestions(): Observable<DriverSuggestion> {
		return this.driverSuggestions;
	}

	/**
	Generic Error handling method for http calls
	*/
	private handleError<T>(operation = 'operation not set', result?: T) {
		return (error: any): Observable<T> => {

			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			//  this.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
	/** returns if a movement to the last locations has happened*/
	private collectLastGeolocations(newLocation: any): boolean {
		var oldLocation = this.lastLocationArry[this.lastLocationArry.length - 1];
		var movement = false;
		if (oldLocation != undefined) {
			//distance locationbefore to newlocation
			var distance = this.findGeoLocationDistance(oldLocation.lat, oldLocation.lon, newLocation.lat, newLocation.lon);
			if (distance > minMovementMeters) { // meters add to lastlocation array
				movement = true;
				this.lastLocationArry.push(newLocation);
				if (this.lastLocationArry.length > locationHistoryPoints) {
					this.lastLocationArry.splice(0, 1); // splice the old locations and keep latest 10
				}
			}
		} else {
			// no latest old location
			this.lastLocationArry.push(newLocation);
			movement = true;
		}
		return movement;
	}

	// this is to find the distance between two geo locations in meters
	private findGeoLocationDistance(lat1, lon1, lat2, lon2) {

		var earthRadius = 6371e3;

		var dLat = this.convertDegreesToRadians(lat2 - lat1);
		var dLon = this.convertDegreesToRadians(lon2 - lon1);
		var rLat1 = this.convertDegreesToRadians(lat1);
		var rLat2 = this.convertDegreesToRadians(lat2);

		var angularDistance = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rLat1) * Math.cos(rLat2);
		var chordLength = 2 * Math.atan2(Math.sqrt(angularDistance), Math.sqrt(1 - angularDistance));
		var distance = earthRadius * chordLength;

		console.log("Distance:" + distance);
		return distance;
	}

	// this is to convert two geo locations numeric degrees to radians
	private convertDegreesToRadians(value) {
		var PI = 3.1415926535;
		return value / 180.0 * PI;
	}

	// this is to alert the user for a location permission
	openLocationPermissionDialog() {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;

		this.dialog.open(AppPermissionDialog, dialogConfig);
	}
	
	// this is to get www.iunera.com blog using wordpress
	getBackgroundBlog() {
		if (window.location.href.indexOf("/de/") > -1) 
			this.background_base_path = this.background_base_path+"&lang=de";
		return this.http.get(this.background_base_path).pipe(retry(2), catchError(this.blogHandleError));
	}
	
	blogHandleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error('Backend returned code ${error.status},' + 'body was: ${error.error}');
		}
		// return an observable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}

}

// Component added to open simple Dialog for Application Location permission.
@Component({
	selector: 'app-permission-dialog',
	templateUrl: 'app-permission-dialog.html',
})
export class AppPermissionDialog { }

