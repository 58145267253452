// added localize constants here to identify line number for transaltion files 
const errorPerceivedOccupation = $localize`:@@percievedOccupation.errorMessage:Perceived Occupation *`;
const submitMsg = $localize`:@@submit.successMessage:Submitted`;

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { RideOccupationReportDTO, DriverSuggestion } from '../apiDataTypes';
import { Observable, timer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DriverCodeDialogComponent } from '../nav/nav-driver-code-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-driver-occupancy',
	templateUrl: './driver-occupancy.component.html',
	styleUrls: ['./driver-occupancy.component.css']
})
export class DriverOccupancyComponent implements OnInit, OnDestroy {

	driverOccupancyForm = this.fb.group({
		origin: null,
		destination: [null, Validators.required],
		line: [null, Validators.required],
		perceivedOccupation: [null, Validators.required],
		faceMask: [null, Validators.required]
	});

	myDriverControl = new FormControl();

	driverObservableSuggestions: Observable<DriverSuggestion>;

	driverSuggestedDepartureOrigins: Observable<string[]>;

	driverSuggestedDestinationOrigins: Observable<string[]>;

	driverSuggestedLines: Observable<string[]>;

	lastlocation: any;

	objReport: RideOccupationReportDTO;

	constructor(
		private fb: FormBuilder,
		private apiService: ApiService,
		private activatedRoute: ActivatedRoute,
		private snackBar: MatSnackBar,
		private router: Router,
		public dialog: MatDialog,
		private cookieService: CookieService) {

		this.activatedRoute.queryParams.subscribe(data => {
			console.log(data);
		});

	}

	//accesses the UI field and once a value changes by user entry of letters it pipes the already entered string into a filter 
	//to show only the matching suggestions
	private listenToUserEntrySuggestionsFilter() {

		this.driverSuggestedDepartureOrigins = this.driverOccupancyForm
			.get('origin')
			.valueChanges.pipe(startWith(''),
				withLatestFrom(this.driverObservableSuggestions),
				map(([q, observableSuggestions]) => {
					let filterValue = "";
					if(q != undefined)
						filterValue = q.toLowerCase();

					return observableSuggestions.departureStations.filter(suggestion => suggestion.toLowerCase()
						.indexOf(filterValue) > -1);
				})
			);

		this.driverSuggestedDestinationOrigins = this.driverOccupancyForm
			.get('destination')
			.valueChanges.pipe(startWith(''),
				withLatestFrom(this.driverObservableSuggestions),
				map(([q, observableSuggestions]) => {
					let filterValue = "";
					if(q != undefined)
						filterValue = q.toLowerCase();

					return observableSuggestions.destinationStations.filter(suggestion => suggestion.toLowerCase()
						.indexOf(filterValue) > -1);
				})
			);

		this.driverSuggestedLines = this.driverOccupancyForm
			.get('line')
			.valueChanges.pipe(startWith(''),
				withLatestFrom(this.driverObservableSuggestions),
				map(([q, observableSuggestions]) => {
					let filterValue = "";
					if(q != undefined)
						filterValue = q.toLowerCase();

					return observableSuggestions.lines.filter(suggestion => suggestion.toLowerCase()
						.indexOf(filterValue) > -1);
				})
			);

	}
	timer: any;
	ngOnInit() {
		if(this.cookieService.get('drivercode') == undefined) 
			this.openDriverCodeDialog();

		this.timer = timer(environment.initialDelay, environment.period).subscribe(() => {
			console.log(environment.initialDelay+"......"+environment.period);
			this.loadingDriverSuggestions();
		});
		
	}
	
	ngOnDestroy() {
		this.timer.unsubscribe();
	}

	errorPerceivedOccupation: any; // display mandatory error message for percieved occupation input
	originSetValue: any; // set default value on submit for auto filled origin
	destinationSetValue: any; // set default value on submit for auto filled destination

	// To post the ride report information to backend
	onDriverSubmit() {
		if (this.driverOccupancyForm.value.perceivedOccupation == null)
			this.errorPerceivedOccupation = errorPerceivedOccupation;

		console.log(this.driverOccupancyForm.value);
		if (this.driverOccupancyForm.value.line != null && this.driverOccupancyForm.value.perceivedOccupation != null) {

			console.log(this.driverOccupancyForm.value);
			console.log(this.apiService.lastlocation);
			var driverRideInput = {
				"line" : this.driverOccupancyForm.value.line,
				"driverCode" : this.cookieService.get('drivercode'),
				"perceivedOccupation" : this.driverOccupancyForm.value.perceivedOccupation,
				"faceMask" : this.driverOccupancyForm.value.faceMask,
				"origin" : this.driverOccupancyForm.value.origin,
				"destination" : this.driverOccupancyForm.value.destination,
				"lastlocatiotion" : this.apiService.lastlocation, // last geo locations
				"submissionTime" : new Date(), //occupancy report submitted time by driver
				"departureTime" : null, //start time from api
				"nextDestination" : null, //time of expected arrival from api
				"originDestination" : null //time of expected departure from api
			};
			this.apiService.postDriverOccupationReport(driverRideInput)
				.subscribe(
					response => {
						this.responseSnackBar(submitMsg);
						console.log('Success', response.submissionTime + ".." + response.destination)  // this is to log the response status
						this.reloadDriverOccupancyReportDetails(response.origin, response.destination);
					});
		}

	}

	reloadDriverOccupancyReportDetails(origin, destination) {
		this.driverOccupancyForm.get('origin').setValue(origin);
		this.driverOccupancyForm.get('destination').setValue(destination);
		this.driverOccupancyForm.get('perceivedOccupation').setValue('');
		this.driverOccupancyForm.get('faceMask').setValue('');
		this.errorPerceivedOccupation = '';

	}

	// this is a popup snack bar on success/error of a post api call -- future refernce
	responseSnackBar(message: string) {
		this.snackBar.open(message, "", { duration: 2000, });
	}

	// this is to open a dialog on success.error of a post api call
	openDriverCodeDialog() {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;

		this.dialog.open(DriverCodeDialogComponent, dialogConfig);
	}


	// loading driver suggestions every 2 minutes
	loadingDriverSuggestions() {
		
		this.driverObservableSuggestions = this.apiService.getDriverSuggestions();
		this.driverObservableSuggestions.subscribe(suggestedOrigins => {

			// This will retrieve the first element from the suggestions depature stations array and destination stations array and set to default 
			if(suggestedOrigins.departureStations[0] != undefined && suggestedOrigins.destinationStations[0] != undefined) {
				this.driverOccupancyForm.get('origin').setValue(suggestedOrigins.departureStations[0]); 
				this.driverOccupancyForm.get('destination').setValue(suggestedOrigins.destinationStations[0]);
			}

			// listening to user entry auto suggestion from api and departures nearer to latest geo location captured.
			this.listenToUserEntrySuggestionsFilter();
		});
	}
}



