<div>
	<div class="grid-container">
		<h1 class="mat-h2" *ngIf="(stopDepartures | async)?.length > 0; else elseBlock" i18n>Departures near me</h1>
		<div *ngFor="let stop of stopDepartures | async;">
			<mat-card class="title-card">
				<mat-card-header>
					<mat-card-title > <mat-chip (click)="originClick(stop.originStop.providerKey)"> 
					<mat-icon>departure_board</mat-icon> {{stop.originStop.label}}  </mat-chip> </mat-card-title>
				</mat-card-header> 
				<app-departure-table [departures]="stop.departureDTOs"></app-departure-table> 
			</mat-card>
		</div>
		
		<ng-template #elseBlock>  
 			<mat-card-title style="text-align:center" i18n>No Departure stations near you!</mat-card-title>
		</ng-template>  
 
		<mat-grid-list cols="2" rowHeight="400px"> 
			<mat-grid-tile *ngFor="let card of cards | async" [colspan]="2"	[rowspan]="card.rows"> 
				<mat-card class="dashboard-card"> 
					<mat-card-header>
						<mat-card-title> <span i18n>Tips</span> 
							<button mat-icon-button class="more-button" [matMenuTriggerFor]="menu"
													aria-label="Toggle menu">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu" xPosition="before">
								<button mat-menu-item i18n>Expand</button>
								<button mat-menu-item i18n>Remove</button>
							</mat-menu> 
						</mat-card-title> 
					</mat-card-header> 
					<mat-card-content class="dashboard-card-content">
					<table>
						<ng-container cdkColumnDef="icon">
    						 <tr> <td><img src="assets/img/face-mask.svg" class="tips-image-icon"/></td>
    						 	  <td i18n>Wear mask to protect yourself and others.</td>
    						 </tr>
    						 <tr> 
    						 	  <td><img src="assets/img/avoid.svg" class="tips-image-icon"/></td>  
    						 	  <td i18n>When possible, consider traveling during non-peak hours when there are likely to be fewer people.</td> 
    						 </tr>
    						 <tr>
    						 	  <td><img src="assets/img/social-distancing.svg" class="tips-image-icon"/></td>
    						 	  <td i18n>Practice physical distancing.</td> 
    						 </tr>
    						 <tr>
    						 	  <td><img src="assets/img/touch-screen.svg" class="tips-image-icon"/></td>
    						 	  <td i18n>Avoid touching surfaces.</td> 
    						 </tr>
    						 <tr> 
    						 	  <td><img src="assets/img/handwash.svg" class="tips-image-icon"/></td>
    						 	  <td i18n>Practice hand hygiene.</td>
    						 </tr>
  						</ng-container>
					</table>
					<div class="privacy-icon"><span><a mat-button href="https://www.iunera.com/imprint/" target="_blank" i18n>Imprint</a></span>
				 	<span><a mat-button href="https://www.iunera.com/privacy-policy/" target="_blank" i18n>Privacy Policy</a></span>  </div>
					</mat-card-content> 
				</mat-card> 
			</mat-grid-tile> 
		</mat-grid-list>
	</div>
	<button routerLink="/occupancy" queryParamsHandling="merge"
		class="extended-fab-button" mat-fab color="primary"
		style="position: fixed !important; background: #b5002b;">
			<mat-icon>add</mat-icon>
				<span class="extended-fab-button__text" i18n>Report occupation</span>
	</button>
</div>
