<form [formGroup]="driverOccupancyForm" novalidate (ngSubmit)="onDriverSubmit()" novalidate>
	<mat-card-content>
  	<div class="row">
		<mat-form-field class="full-width" floatLabel="never">
	<!--  	<mat-label><div *ngIf="driverLikelyDeparatureStation != undefined" >{{driverLikelyDeparatureStation | async}}</div></mat-label>   -->
		<mat-hint i18n>Enter departure station</mat-hint> 
		<input #origin i18n-placeholder
			   placeholder="Departure station"
			   type="text" 
			   formControlName="origin" 
			   matInput
			   [formControl]="myDriverControl" 
			   [matAutocomplete]="auto"> 
		<mat-autocomplete #auto="matAutocomplete">
		<mat-option *ngFor="let departureStations of driverSuggestedDepartureOrigins | async" [value]="departureStations">
		<span>{{departureStations}}</span>
		</mat-option> 
		</mat-autocomplete> 
		</mat-form-field>
	</div>
		
 	<div class="row">
		<mat-form-field class="full-width" floatLabel="never">
	<!--	<mat-label><div *ngIf="driverLikelyDestinationStation != undefined" >{{driverLikelyDestinationStation | async}}</div></mat-label> -->
		<mat-hint i18n>Enter destination station</mat-hint> 
		<input i18n-placeholder 
			   placeholder="Destination station" 
			   type="text" 
			   formControlName="destination" 
			   matInput
			   [formControl]="myDriverControl" 
			   [matAutocomplete]="autoNationality"> 
		<mat-autocomplete #autoNationality="matAutocomplete">

		<mat-option *ngFor="let destinationStations of driverSuggestedDestinationOrigins | async" [value]="destinationStations">
		<span>{{destinationStations}}</span>
		</mat-option> 
		</mat-autocomplete> 
		</mat-form-field>
	</div>
	
	<div class="row">
		<mat-form-field class="full-width" floatLabel="never">
		<mat-label i18n>Station line</mat-label>
		<mat-hint i18n>Enter station line</mat-hint> 
		<input i18n-placeholder 
			   placeholder="Station line"
			   type="text" 
			   formControlName="line" 
			   matInput
			   [formControl]="myDriverControl" 
			   [matAutocomplete]="autoNationality2" required > 
		<mat-autocomplete #autoNationality2="matAutocomplete">
		<mat-option *ngFor="let lines of driverSuggestedLines | async" [value]="lines">
		<span>{{lines}}</span>
		</mat-option> 
		</mat-autocomplete> 
		</mat-form-field>
	</div>
	<hr />

	
	<mat-error *ngIf="driverOccupancyForm.get('perceivedOccupation').hasError('required')">{{errorPerceivedOccupation}}</mat-error>  
	<div class="row">
	<mat-button-toggle-group formControlName="perceivedOccupation"> 
		<mat-button-toggle value="SPARSE">
			<img src="assets/img/empty.svg" class="image-icon" style="height: 30px;"/> 
		</mat-button-toggle> 
		<mat-button-toggle value="SEATING_HALF_OCCUPIED">
			<img src="assets/img/half.svg" class="image-icon" style="height: 30px;"/>
		</mat-button-toggle> 
		<mat-button-toggle value="SEATING_OCCUPIED"> 
			<img src="assets/img/full.svg" class="image-icon" style="height: 30px;"/>
		</mat-button-toggle> 
		<mat-button-toggle value="CROWDED">
			<img src="assets/img/crowded.svg" class="image-icon" style="height: 30px;"/>
		</mat-button-toggle> 
	</mat-button-toggle-group>
	</div>
	<hr/>
	
	<div class="row">
	<mat-button-toggle-group formControlName="faceMask"> 
		<mat-button-toggle value="1">
			<img src="assets/img/mask.svg" class="image-icon" style="height: 30px;"/> 
		</mat-button-toggle> 
		<mat-button-toggle value="2">
			<img src="assets/img/nomask.svg" class="image-icon" style="height: 30px;"/>
		</mat-button-toggle> 
	</mat-button-toggle-group>
	</div>
	<hr/>

	</mat-card-content>
		
	<mat-card-actions>
		<div>
	      	<button mat-fab style="background: #b5002b;">
				<img src="assets/img/upload.svg" class="image-icon" style="height: 30px;"/>
	      	</button>
      	</div>
	</mat-card-actions>
</form>
